<!-- Start Content-->
<div class="container-fluid">

    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box page-title-box-alt">
                <h4 class="page-title">Bio-Medical Waste Register</h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">

                        <li class="breadcrumb-item"><a href="javascript: void(0);">Clinic</a></li>
                        <li class="breadcrumb-item active">Bio-Medical Waste Register</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <!-- end page title -->

    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-header">
                    <h5>Bio-Medical Waste Register</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xs-12">
                            <form [formGroup]="bmwRegisterFormGroup" class="" (ngSubmit)="save()">
                                <div class="alert alert-danger" *ngIf="error_message">{{error_message}}</div>
                                <div class="alert alert-success" *ngIf="success_message">{{success_message}}</div>
                                <h5>Opening Stock</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="opening_stock_white_bag" class="form-label">White
                                                Bag <span style="color: red;">*</span></label>
                                            <input value="0" class="form-control" type="number"
                                                id="opening_stock_white_bag" formControlName="opening_stock_white_bag">
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="opening_stock_blue_bag" class="form-label">Blue
                                                Bag <span style="color: red;">*</span></label>
                                            <input value="0" class="form-control" type="number"
                                                id="opening_stock_blue_bag" formControlName="opening_stock_blue_bag">
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="opening_stock_yellow_bag" class="form-label">Yellow Bag <span
                                                    style="color: red;">*</span></label>
                                            <input value="0" class="form-control" type="number"
                                                id="opening_stock_yellow_bag"
                                                formControlName="opening_stock_yellow_bag">
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="opening_stock_red_bag" class="form-label">Red Bag
                                                <span style="color: red;">*</span></label>
                                            <input value="0" class="form-control" type="number"
                                                id="opening_stock_red_bag" formControlName="opening_stock_red_bag">
                                        </div>
                                    </div>
                                </div>
                                <h5>Used</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="number_used_white_bag" class="form-label">White
                                                Bag <span style="color: red;">*</span></label>
                                            <input value="0" class="form-control" type="number"
                                                id="number_used_white_bag" formControlName="number_used_white_bag">
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="number_used_blue_bag" class="form-label">Blue
                                                Bag <span style="color: red;">*</span></label>
                                            <input value="0" class="form-control" type="number"
                                                id="number_used_blue_bag" formControlName="number_used_blue_bag">
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="number_used_yellow_bag" class="form-label">Yellow Bag <span
                                                    style="color: red;">*</span></label>
                                            <input value="0" class="form-control" type="number"
                                                id="number_used_yellow_bag" formControlName="number_used_yellow_bag">
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="number_used_red_bag" class="form-label">Red Bag
                                                <span style="color: red;">*</span></label>
                                            <input value="0" class="form-control" type="number" id="number_used_red_bag"
                                                formControlName="number_used_red_bag">
                                        </div>
                                    </div>
                                </div>

                                <h5>Total Weight</h5>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="total_weight_white_bag" class="form-label">White
                                                Bag <span style="color: red;">*</span></label>
                                            <div class="input-group">
                                                <input value="0" class="form-control" type="number"
                                                    id="total_weight_white_bag"
                                                    formControlName="total_weight_white_bag">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">gms</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="total_weight_blue_bag" class="form-label">Blue
                                                Bag <span style="color: red;">*</span></label>
                                            <div class="input-group">
                                                <input value="0" class="form-control" type="number"
                                                    id="total_weight_blue_bag" formControlName="total_weight_blue_bag">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">gms</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="total_weight_yellow_bag" class="form-label">Yellow Bag <span
                                                    style="color: red;">*</span></label>
                                            <div class="input-group">
                                                <input value="0" class="form-control" type="number"
                                                    id="total_weight_yellow_bag"
                                                    formControlName="total_weight_yellow_bag">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">gms</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="mb-2">
                                            <label for="total_weight_red_bag" class="form-label">Red Bag
                                                <span style="color: red;">*</span></label>
                                            <div class="input-group">
                                                <input value="0" class="form-control" type="number"
                                                    id="total_weight_red_bag" formControlName="total_weight_red_bag">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">gms</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="mb-2 text-left">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!bmwRegisterFormGroup.valid">Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-12 col-md-12">
            <div class="card">
                <div class="card-body">
                    <div class="row table-responsive">
                        <table class="table table-striped table-hover table-bordered">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Opening Stock White</th>
                                    <th>Opening Stock Blue</th>
                                    <th>Opening Stock Yellow</th>
                                    <th>Opening Stock Red</th>
                                    <th>Used Stock White</th>
                                    <th>Used Stock Blue</th>
                                    <th>Used Stock Yellow</th>
                                    <th>Used Stock Red</th>
                                    <th>Total Weight White</th>
                                    <th>Total Weight Blue</th>
                                    <th>Total Weight Yellow</th>
                                    <th>Total Weight Red</th>
                                    <th>Closing Stock White</th>
                                    <th>Closing Stock Blue</th>
                                    <th>Closing Stock Yellow</th>
                                    <th>Closing Stock Red</th>
                                    <td>Total Bags Used</td>
                                    <td>Total Weight</td>
                                    <th>Created By</th>
                                    <th>Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="text-align: center;" *ngIf="!bmw_list.length">
                                    <td colspan="100">No Content</td>
                                </tr>
                                <tr *ngFor="let s of bmw_list; let i = index">
                                    <td class="col-1">{{i + 1}}</td>
                                    <td>{{s.opening_stock_white_bag}}</td>
                                    <td>{{s.opening_stock_blue_bag}}</td>
                                    <td>{{s.opening_stock_yellow_bag}}</td>
                                    <td>{{s.opening_stock_red_bag}}</td>
                                    <td>{{s.number_used_white_bag}}</td>
                                    <td>{{s.number_used_blue_bag}}</td>
                                    <td>{{s.number_used_yellow_bag}}</td>
                                    <td>{{s.number_used_red_bag}}</td>
                                    <td>{{s.total_weight_white_bag}} gms</td>
                                    <td>{{s.total_weight_blue_bag}} gms</td>
                                    <td>{{s.total_weight_yellow_bag}} gms</td>
                                    <td>{{s.total_weight_red_bag}} gms</td>
                                    <td>{{s.closing_stock_white_bag}}</td>
                                    <td>{{s.closing_stock_blue_bag}}</td>
                                    <td>{{s.closing_stock_yellow_bag}}</td>
                                    <td>{{s.closing_stock_red_bag}}</td>
                                    <td>{{s.total_bag_used}}</td>
                                    <td>{{s.total_bag_wieght}} gms</td>
                                    <td>{{s.recorded_by.name}}</td>
                                    <td>{{s.date | date: 'short'}}</td>
                                    <td><a class="btn btn-warning" href="#" (click)="selectEntery(s)">Update</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div><!-- end col -->
    </div>
    <!-- end row -->

</div> <!-- container -->
<!-- Dashboard init-->