import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PharmacyService {

    constructor(private http: HttpClient) { }

    getMedicine(location_id: string, date: string, doctor_id: string | null = null) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/medicine/" + location_id
            + "?date=" + date + "&doctor_id=" + doctor_id);
    }

    getMedicineStockList(location_id: string, min_stock: string = '', expirey: string = '') {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/medicine/medicine-list/" + location_id + "/?min_stock=" + min_stock + "&is_expired=" + expirey);
    }

    dispenseMedicine(location_id: string, date: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/medicine/" + location_id
            + "?date=" + date);
    }

    createMedicine(data: any) {
        return this.http.post<ResponseObject>(environment.apiEndpoint + "/medicine", data);
    }

    updateMedicine(data: any) {
        return this.http.put<ResponseObject>(environment.apiEndpoint + "/medicine/" + data.medicine_id, data);
    }

    getAllMedicines(location_id: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/medicine/" + location_id);
    }

    getAllMedicineLedger(location_id: string, from_date: string, to_date: string, medicine_id?: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/medicine/ledger/" + location_id +
            "?from_date=" + from_date + "&to_date=" + to_date + "&medicine_id=" + medicine_id);
    }

    getMedicineStockListMonthWise(location_id: string, month: number) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/medicine/ledger/day-wise/" + location_id +
            "?month=" + month);
    }

    addStock(medicine_id: string, qty: Number) {
        return this.http.patch<ResponseObject>(environment.apiEndpoint + "/medicine/add-stock/" + medicine_id + "?quantity=" + qty, {});
    }

    getTopMedsLocation(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/medicine/top-med-list?location_ids=" + location_ids);
    }

    getExpiringMeds(location_ids: string) {
        return this.http.get<ResponseObject>(environment.apiEndpoint + "/medicine/top-expirey-list?location_ids=" + location_ids);
    }
}
