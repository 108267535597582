
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseObject } from 'src/app/shared/model/response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/admin/user/user.service';
import { AuthService } from 'src/app/shared/auth.service';
import { SterilizationRegisterService } from './sterilization-register.service';

@Component({
    selector: 'app-user',
    templateUrl: './sterilization-register.component.html',
    styleUrls: ['./sterilization-register.component.css']
})
export class SterilizationRegisterComponent {

    public error_message: string = "";
    public success_message: string = "";
    public sterilization_list: any = [];
    public action = 'SAVE';
    public entry_id = '';


    public sterilizationRegisterFormGroup = new FormGroup({
        equipment_name: new FormControl("", [Validators.required]),
        location_id: new FormControl(this.activatedroute.snapshot.queryParams["l"], [Validators.required]),
        company_id: new FormControl(this.activatedroute.snapshot.queryParams["c"], [Validators.required]),
        remarks: new FormControl("", [Validators.required])
    });

    constructor(private sterilizationRegisterService: SterilizationRegisterService,
        private activatedroute: ActivatedRoute) { }

    private getRegisterContent() {
        this.sterilizationRegisterService.get(this.activatedroute.snapshot.queryParams["l"])
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.sterilization_list = res.data;
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    ngOnInit() {
        this.getRegisterContent();
    }

    private createdepartment(data: any) {
        this.sterilizationRegisterService.create(data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 201) {
                    this.getRegisterContent();
                }
                else {
                    this.error_message = res.message;
                }
                this.sterilizationRegisterFormGroup.reset();
            });
    }

    private updateSterilizationRegister(id: string, data: any) {
        this.sterilizationRegisterService.update(id, data)
            .subscribe((res: ResponseObject) => {
                this.error_message = "";
                if (res.statusCode === 200) {
                    this.getRegisterContent();
                    this.sterilizationRegisterFormGroup.reset();
                    this.action = 'SAVE';
                    this.entry_id = '';
                }
                else {
                    this.error_message = res.message;
                }
            });
    }

    save() {
        this.error_message = "";
        this.success_message = "";

        if (!this.sterilizationRegisterFormGroup.valid) {
            this.error_message = "Invalid form inputs";
            return;
        }
        let data = {
            ... this.sterilizationRegisterFormGroup.value,
        }


        if (this.action === "SAVE")
            this.createdepartment(data);
        else
            this.updateSterilizationRegister(this.entry_id, data);

    }

    selectEntery(obj: any) {
        this.action = "UPDATE";
        this.entry_id = obj._id;
        this.sterilizationRegisterFormGroup.patchValue(obj);
    }
}

